import React from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemText, Typography
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useRegulamenteStyles from "../styles/regulamente";

const Regulamente : React.FC = () => {
    const regulamenteStyles = useRegulamenteStyles();

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography className={regulamenteStyles.title}>REGULAMENTE</Typography>
            </Grid>
           <Grid item xs={12} className={regulamenteStyles.wrapperAccordion}>

                <Accordion defaultExpanded>
                    <AccordionSummary
                        className={regulamenteStyles.accordionSummary}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="2024-content"
                        id="2024-header"
                    >
                        2025
                    </AccordionSummary>
                    <AccordionDetails className={regulamenteStyles.accordionDetails}>
                        <List className={regulamenteStyles.list}>
                            <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2025/Regulament Oficial Sampling Kozel Dark 500 ml_Sezamo_Mar_2025_Semnat.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Regulament Oficial Sampling Kozel Dark 500 ml - Sezamo Martie 2025`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2025/Regulament Oficial Sampling Kozel Dark 500 ml_Freshful_Feb-Mar_2025_Semnat.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Regulament Oficial Sampling Kozel Dark 500 ml - Freshful Februarie-Martie 2025`} />
                               </ListItemButton>
                           </ListItem>
                        </List>
                    </AccordionDetails>
                </Accordion>
               <Accordion>
                   <AccordionSummary
                       className={regulamenteStyles.accordionSummary}
                       expandIcon={<ExpandMoreIcon />}
                       aria-controls="2024-content"
                       id="2024-header"
                   >
                       2024
                   </AccordionSummary>
                   <AccordionDetails className={regulamenteStyles.accordionDetails}>
                       <List className={regulamenteStyles.list}>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2024/1-Regulamentul oficial al Campaniei Kozel “Cumpara oricare 2 beri Kozel si primesti pe loc o bere Kozel Dark”.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Regulamentul oficial al Campaniei Kozel “Cumpara oricare 2 beri Kozel si primesti pe loc o bere Kozel Dark”`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2024/2-REGULAMENTUL OFICIAL AL CAMPANIEI „Sampling Doza Kozel Dark 500 ml - Sezamo”.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`REGULAMENTUL OFICIAL AL CAMPANIEI „Sampling Doza Kozel Dark 500 ml - Sezamo”`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2024/3-REGULAMENTUL OFICIAL AL CAMPANIEI „Sampling Doza Kozel Dark 500 ml - Freshful”.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`REGULAMENTUL OFICIAL AL CAMPANIEI „Sampling Doza Kozel Dark 500 ml - Freshful”`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2024/4-Regulament Sampling Auchan 4 mag – 29-30 martie.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Regulament Sampling Auchan 4 mag – 29-30 martie`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2024/5-“Regulament campanie Sampling Kozel Dark – Online Auchan – 1-15 mai”.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`“Regulament campanie Sampling Kozel Dark – Online Auchan – 1-15 mai”`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2024/6-“Regulament Sampling Kozel Dark - - Online Mega-Image – 1-15 mai“.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`“Regulament Sampling Kozel Dark - - Online Mega-Image – 1-15 mai“`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2024/7-Regulament Concurs Kozel x RDW.docx" target="_blank" rel="noopener">
                                   <ListItemText primary={`Regulament Concurs Kozel x RDW`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2024/9-REGULAMENTUL OFICIAL AL CONCURSULUI DIN CADRUL QUEST DAY.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`REGULAMENTUL OFICIAL AL CONCURSULUI DIN CADRUL QUEST DAY`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2024/10-Regulamentul oficial al Campaniei Kozel “Mix Tapan de festival” - Carrefour.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Regulamentul oficial al Campaniei Kozel “Mix Țapăn de festival” - Carrefour`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2024/11-Regulamentul oficial al Campaniei Kozel “Mix Tapan de festival” - Auchan.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Regulamentul oficial al Campaniei Kozel “Mix Țapăn de festival” - Auchan`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2024/12-Regulamentul oficial al Campaniei Kozel “Mix Tapan de festival” - Kaufland.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Regulamentul oficial al Campaniei Kozel “Mix Țapăn de festival” - Kaufland`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2024/13-Regulamentul oficial al Campaniei Kozel “Mix Tapan de festival” - Mega Image.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Regulamentul oficial al Campaniei Kozel “Mix Țapăn de festival” - Mega Image`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2024/14-Regulamentul oficial al Campaniei Kozel “Mix Tapan de festival” - Penny.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Regulamentul oficial al Campaniei Kozel “Mix Țapăn de festival” - Penny.pdf`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2024/15-Regulamentul oficial al Campaniei Kozel “Mix Tapan de festival“ - Profi.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Regulamentul oficial al Campaniei Kozel “Mix Țapăn de festival“ - Profi`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2024/16-Regulamentul oficial Kozel - Cumpara oricare 2 beri Kozel si primesti pe loc o bere Kozel Dark.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Regulamentul oficial Kozel - Cumpara oricare 2 beri Kozel si primesti pe loc o bere Kozel Dark`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2024/17-Act Aditional_KOZEL_reteaua Auchan autentificat.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Act Aditional_KOZEL_reteaua Auchan autentificat`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2024/17-1-Act Aditional_KOZEL_reteaua Auchan autentificat.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Act Aditional_KOZEL_reteaua Auchan nr 2 autentificat`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2024/18-Act Aditional_KOZEL_reteaua Carrefour_autentificat.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Act Aditional_KOZEL_reteaua Carrefour autentificat`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2024/18-1-Act Aditional_KOZEL_reteaua Carrefour_autentificat.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Act Aditional_KOZEL_reteaua Carrefour nr 2 autentificat`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2024/19-Act Aditional_KOZEL_reteaua Kaufland autentificat.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Act Aditional_KOZEL_reteaua Kaufland autentificat`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2024/19-1-Act Aditional_KOZEL_reteaua Kaufland autentificat.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Act Aditional_KOZEL_reteaua Kaufland nr 2 autentificat`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2024/20-Act Aditional_KOZEL_reteaua Mega Image autentificat.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Act Aditional_KOZEL_reteaua Mega Image autentificat`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2024/20-1-Act Aditional_KOZEL_reteaua Mega Image autentificat.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Act Aditional_KOZEL_reteaua Mega Image nr 2 autentificat`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2024/21-Act Aditional_KOZEL_reteaua Penny autentificat.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Act Aditional_KOZEL_reteaua Penny autentificat`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2024/21-1-Act Aditional_KOZEL_reteaua Penny autentificat.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Act Aditional_KOZEL_reteaua Penny nr 2 autentificat`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2024/22-Act Aditional_KOZEL_reteaua Profi autentificat.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Act Aditional_KOZEL_reteaua Profi autentificat`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2024/22-1-Act Aditional_KOZEL_reteaua Profi autentificat.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Act Aditional_KOZEL_reteaua Profi nr 2 autentificat`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2024/23-Regulament Oficial_KOZEL @UNTOLD.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Regulament Oficial_KOZEL @UNTOLD`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2024/24-Regulament Oficial_KOZEL Meeet @UNTOLD.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Regulament Oficial_KOZEL Meeet @UNTOLD`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2024/25-KOZEL x VOCEA ROMANIEI_regulament autentificat.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Regulament Oficial KOZEL x VOCEA ROMANIEI`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2024/26-Regulament_Kozel Trial 2+1 Dark Can Q4 2024.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Campanie KOZEL Trial 2+1 Dark Can`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2024/27-Regulament Kozel - Mixeaza Tapan si Castiga cu Profi.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Regulament Kozel - Mixeaza Tapan si Castiga cu Profi`} />
                               </ListItemButton>
                           </ListItem>
                           {/* <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2024/27-Regulament Kozel - Mixeaza Tapan si Castiga cu Profi.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Regulamentul campaniei Kozel Meeet - Self-implemented`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2024/27-Regulament Kozel - Mixeaza Tapan si Castiga cu Profi.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Regulamentul campaniei Kozel Meeet - Evenimente Speciale`} />
                               </ListItemButton>
                           </ListItem> */}
                       </List>
                   </AccordionDetails>
               </Accordion>
               <Accordion>
                   <AccordionSummary
                       className={regulamenteStyles.accordionSummary}
                       expandIcon={<ExpandMoreIcon />}
                       aria-controls="2023-content"
                       id="2023-header"
                   >
                       2023
                   </AccordionSummary>
                   <AccordionDetails className={regulamenteStyles.accordionDetails}>
                       <List className={regulamenteStyles.list}>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2023/1-Regulament-Mixeaza-Tapan-si-Castiga-Premii-de-Festival-Penny.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Regulament "Mixează Țapăn și Câștigă Premii de Festival" - Penny`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2023/2-Regulament-Mixeaza-Tapan-si-Castiga-Premii-de-Festival-Cora.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Regulament "Mixează Țapăn și Câștigă Premii de Festival" - Cora`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2023/3-Regulament-Mixeaza-Tapan-si-Castiga-Premii-de-Festival-Carrefour.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Regulament "Mixează Țapăn și Câștigă Premii de Festival" - Carrefour`}/>
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2023/4-Regulament-Mixeaza-Tapan-si-Castiga-Premii-de-Festival-Mega-Image.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Regulament "Mixează Țapăn și Câștigă Premii de Festival" - Mega Image`}/>
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2023/5-Regulament-Virgin-Radio-si-Kozel-te-trimit-la-Massif.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Regulament Virgin Radio si Kozel te trimit la Massif`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2023/6-Regulament-prima-comanda-Freshful-Kozel-Dark.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Regulament prima comanda Freshful Kozel Dark`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2023/7-Regulament-Sampling-Cora-Kozel-Mix.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Regulament Sampling Cora Kozel Mix`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2023/8-REGULAMENT_SAMPLING_KOZEL_DARK_AUCHAN_DR_TABEREI.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Regulament Sampling Kozel Dark Auchan DR. Taberei (24-26 FEB)`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2023/9-REGULAMENTUL_OFICIAL_AL_CAMPANIEI_KOZEL_PARTY_MIX.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Regulamentul Oficial al Campaniei Kozel Party MIX`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2023/10-Regulament_Sampling_Online_Kozel_Mix_Mega_Image.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Regulament Sampling Online Kozel Mix Mega Image`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2023/11-Rules_Regulament_Sampling_Online_Kozel_Mix_Mega_Image.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Rules Regulament Sampling Kozel Mix Mega Image`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2023/12-REGULAMENT_SAMPLING_AUCHAN_DR_TABEREI_KOZEL_9-11_IUNIE_Final.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Regulamentul Oficial al Campaniei Sampling Kozel Dark – Auchan Drumul Taberei`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2023/13-Regulamentul_oficial_Kozel_La_oricare_bere_cumparata_primesti_pe_loc_cealalta_varianta.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`La oricare bere Kozel cumparata, primesti pe loc cealalta varianta`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2023/14-Regulament_Kozel_Mixeaza_la_Untold.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Kozel Mixeaza la Untold`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2023/15-Regulament_KissFM_KozelMIX.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`KozelMIX la Kiss FM – Kozel te trimite la Untold`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2023/17-REGULAMENT CAMPANIE - UNTOLD 2023  (MYSTERY SHOPPER).pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Regulament campanie – Untold 2023 (Mystery Shopper)`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2023/18-REGULAMENT CAMPANIE UNTOLD 2023 - ECHIPE DE BARMANI.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Regulament Campanie Untold 2023 – Echipe de barmani`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2023/" target="_blank" rel="noopener">
                                   <ListItemText primary={`Regulamentul oficial al campaniei Kozel@Untold – Roata de festival`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2023/20-REGULAMENT_ONLINE_SAMPLING_CORA_KOZEL_DARK.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Regulamentul official al campaniei de sampling – Kozel@Untold 2023`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2023/Publicare_castigatori_Kozel_Mixeaza_la_Untold.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Castigatori Kozel Mixeaza la Untold!`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2023/20-REGULAMENT_ONLINE_SAMPLING_CORA_KOZEL_DARK.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Regulamentul Oficial Al Campaniei`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2023/21-Castigatori_Kozel_MIX_Kiss_FM.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Castigatori Kozel MIX Kiss FM`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2023/22-Lista_castigatori_Top_Sellers.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Castigatori Top Sellers Untold`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2023/23-Cumpara.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Cumpara oricare 2 beri Kozel si primesti pe loc o bere Kozel Dark`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2023/24-Regulament_Freshful.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Sampling Kozel Dark CAN 500ml – Freshfull online`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2023/25-Regulament_Facebook.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Regulament Concurs Facebook`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2023/26-Lista-castigatori.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Câștigători concurs “Țapul Kozel caută un as în rime țapene”`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2023/27-Regulament_Oficial_Sampling_Kozel_Dark_Semnat.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`REGULAMENTUL OFICIAL AL CAMPANIEI „Sampling Doza Kozel Dark 500 ml - Freshful”`} />
                               </ListItemButton>
                           </ListItem>
                       </List>
                   </AccordionDetails>
               </Accordion>
               <Accordion>
                   <AccordionSummary
                       className={regulamenteStyles.accordionSummary}
                       expandIcon={<ExpandMoreIcon />}
                       aria-controls="2022-content"
                       id="2022-header"
                   >
                       2022
                   </AccordionSummary>
                   <AccordionDetails className={regulamenteStyles.accordionDetails}>
                       <List className={regulamenteStyles.list}>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2022/1-Regulament Kozel Lager Sampling_ BOB_signed.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Regulament "Sampling Premium Lager CAN 500 ml"`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2022/2-Degustare-kozel-bere-tapana-din-cehia.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Gusta berea care nu s-a lansat peste tot!`} />
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2022/3-Regulament-kozel_degustare-lansare.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Regulament Kozel - Degustare lansare`}/>
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2022/4-REGULAMENTUL-OFICIAL-AL-CAMPANIEI-TAPSTERUL-ANULUI-KOZEL.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Regulament Tapsterul Anului Kozel`}/>
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2022/5-Regulament-La-oricare-bere-Kozel-cumparata-primesti-pe-loc-cealalta-varianta.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Regulamentul oficial al campaniei`}/>
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2022/6-Regulament-kozel-te-trimite-la-untold.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Regulament Kozel te trimite la UNTOLD`}/>
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2022/7-Locatii-Sesiunea-de-degustare-Kozel-v2.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Regulament Sesiunea de degustare`}/>
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2022/8-Regulament-Sampling-Kozel-Selgros.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Locatii – Sesiunea de degustare Kozel`}/>
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2022/9-Regulament-Premii-Tapene.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Regulament sampling Kozel – Selgros`}/>
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2022/10-Regulament-Reciclare-UNTOLD.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Regulament Premii Tapene`}/>
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2022/11-Regulament-Premii-Tapene-Act-Aditional-prelungire.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Regulament Reciclare UNTOLD`}/>
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2022/12-La-oricare-bere-Kozel-cumparata-primesti-pe-loc-cealalta-varianta.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Castigatori Premii Tapene Kozel`}/>
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2022/13-REGULAMENTUL-OFICIAL-AL-CAMPANIEI-PROMOTIONALE_CM-Fotbal.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Regulament Premii Tapene – Act Aditional prelungire`}/>
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2022/14-Regulament_Party-cu-Kozel.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`La oricare bere Kozel cumparata primesti pe loc cealalta varianta`}/>
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2022/15-REGULAMENTUL-OFICIAL-AL-CAMPANIEI-PROMOTIONALE_CM-Fotbal.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Regulament Kozel cinsteste Campionatul Mondial`}/>
                               </ListItemButton>
                           </ListItem>
                           <ListItem disablePadding>
                               <ListItemButton component="a" href="/pdf/2022/16-Regulament_Party-cu-Kozel.pdf" target="_blank" rel="noopener">
                                   <ListItemText primary={`Regulamentul Oficial al campaniei Party cu Kozel`}/>
                               </ListItemButton>
                           </ListItem>
                       </List>
                   </AccordionDetails>
               </Accordion>
           </Grid>
        </Grid>
    )
}

export default Regulamente;